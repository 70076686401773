import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import LayoutContainer from '../containers/layout';
import DividerComponent from '../components/divider';
import Markdown from 'react-markdown';

const PricingPage = () => {
  const data = useStaticQuery(query);

  const renderChildren = (el, i) =>
    el.children ? (
      <div key={i}>
        <div className={`henderson-font text-xs text-black font-semibold ${!el.children[0].price ? 'mb-8' : 'mb-3'} ${i > 0 && 'mt-8'}`}>
          {el.title}
        </div>
        {el.children.map((c, i) => renderChildren(c, i))}
      </div>
    ) : (
      <div key={i} className="flex justify-between mb-3">
        <div className="text-black mr-6">{el.title}</div>
        <div className="flex text-right">
          <div className="text-blue-600 font-medium">{el.price}</div>
          {el.price && !isNaN(el.price) && <div className="text-[0.5rem] pt-1 pl-0.5 text-blue-600">PLN</div>}
        </div>
      </div>
    );

  return (
    <LayoutContainer title={data.strapiPricing.title} description={data.strapiPricing.paymentDescription}>
      <div className="container mx-auto px-6 pt-16 mb-12">
        <section className="mb-12">
          <div className="text-4xl lg:text-5xl text-black mb-3">{data.strapiPricing.title}</div>
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:w-3/5 lg:pr-40">
              <div className="henderson-font text-xs text-black font-semibold mb-12">{data.strapiPricing.coursesSubtitle}</div>

              {data.strapiPricing.prices.map((el, i) => (
                <div key={i}>
                  <div className="text-xl text-black mb-7">{el.title}</div>
                  {el.children.map((c, i) => renderChildren(c, i))}
                  {data.strapiPricing.prices.length != i + 1 && <div className="w-full h-0.5 bg-gray-200 my-12"></div>}
                </div>
              ))}
            </div>

            <div className="w-full lg:w-2/5 lg:pl-40">
              <div className="henderson-font text-xs text-black font-semibold mb-6">{data.strapiPricing.bonusesSubstitle}</div>

              {data.strapiPricing.bonuses.map((el, i) => (
                <div key={i} className="mb-12">
                  <div className="text-3xl text-blue-600 mb-4">{el.bonus}</div>
                  <div>{el.description}</div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="mb-12">
          <DividerComponent />
        </section>

        <section>
          <div className="w-full lg:w-3/5 markdown mb-12">
            <div className="text-xl text-black mb-8">Płatności</div>
            <Markdown className="no-widows w-full markdown">{data.strapiPricing.paymentDescription}</Markdown>
          </div>
        </section>
      </div>
    </LayoutContainer>
  );
};

const query = graphql`
  query {
    strapiPricing {
      title
      coursesSubtitle
      bonusesSubstitle
      prices {
        title
        children {
          title
          price
          children {
            title
            price
            children {
              title
              price
            }
          }
        }
      }
      paymentDescription
      bonuses {
        bonus
        description
      }
    }
  }
`;

export default PricingPage;
